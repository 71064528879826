<template>

  <div class="contacto-container">
    <app-header rootClassName="header-root-class-name3"></app-header>

    <div class="contacto-hero">
      <div class="contacto-container1">
        <form v-on:submit.prevent="validarCampos()">
          <div class="contacto-content-container">
            <h1 class="contacto-text">
              <span class="Heading2">{{ $t('contact.title') }}</span>
              <br/>
              <br/>
            </h1>
            <div class="contacto-input-container">
              <div class="contacto-container2 input">
                <i class="fa fa-user color-icons"></i>
                <input
                    type="text"
                    :placeholder='$t("contact.name")'
                    v-model="contact.name"
                    class="contacto-textinput input"
                    required
                />
              </div>
              <div class="contacto-container3 input">
                <i class="fa fa-envelope color-icons"></i>
                <input
                    type="email"
                    :placeholder='$t("contact.email")'
                    v-model="contact.email"
                    class="contacto-textinput1 input"
                    required
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                    title="Por favor ingresa un correo válido"
                />
              </div>
              <div class="contacto-container4 input">
                <i class="fa fa- fa-file-alt color-icons"></i>
                <textarea
                    rows="5"
                    :placeholder='$t("contact.message")'
                    v-model="contact.message"
                    class="contacto-textarea textarea"
                    required
                ></textarea>
              </div>
              <div class="contacto-container5 input">
                <button class="contacto-button button">{{ $t("contact.send") }}</button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3976.920565795053!2d-74.12057192424999!3d4.608241042450561!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e3f99a8355d3c09%3A0xd5f553d9152c9916!2zQWsuIDUwICMxLTU0LCBCb2dvdMOh!5e0!3m2!1ses!2sco!4v1689729627250!5m2!1ses!2sco"
          class="contacto-iframe" allow="fullscreen"
      ></iframe>
    </div>
    <app-footer rootClassName="footer-root-class-name2"></app-footer>
    <Toast></Toast>
  </div>
</template>

<script>
import AppHeader from '../components/header'
import AppFooter from '../components/footer'
import ContactService from '../services/ContactService'

export default {
  name: 'Contacto',
  props: {},
  components: {
    AppHeader,
    AppFooter,
  }, data() {
    return {
      isDisabled: false,
      enviado: false,
      contact: {
        name: "",
        email: "",
        message: ""
      }
    }
  },

  methods: {
    validarCampos() {
      this.enviado = true;
      if (!this.enviado) {
        return
      } else {
        this.sendMessage(this.contact)
      }
    },
    async sendMessage(contact) {
      this.isDisabled = true;
      await ContactService.send(contact).then(
          (response) => {
            if (response.data) {
              this.$toast.add({
                severity: 'success',
                summary: 'Envio Exitoso!',
                detail: 'Se envió el correo satisfactoriamente. Nos contactaremos pronto!',
                life: 5000
              });
              this.contact.name = ""
              this.contact.email = ""
              this.contact.message = ""
            } else {
              this.$toast.add({
                severity: 'error',
                summary: 'Envio fallido!',
                detail: 'Tenemos problemas con el envío de correo, intantalo mas tarde!',
                life: 5000
              });
            }
          }
      );
      this.isDisabled = false;
    }
  }
}
</script>

<style scoped>
.color-icons {
  color: var(--dl-color-logo-02)
}

.contacto-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.contacto-hero {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  align-self: center;
  min-height: 80vh;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.contacto-container1 {
  width: 40%;
  display: flex;
  margin-right: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
}

.contacto-content-container {
  flex: 1;
  display: flex;
  min-width: 60%;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.contacto-text {
  margin-bottom: var(--dl-space-space-halfunit);
}

.contacto-input-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
}

.contacto-container2 {
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  line-height: 1.5;
  border-width: 1px;
  margin-right: var(--dl-space-space-halfunit);
  border-radius: 32px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  padding: var(--dl-space-space-unit) var(--dl-space-space-oneandhalfunits);
  background-color: #ffffff;
}

.contacto-icon {
  fill: var(--dl-color-grays-gray40);
  width: 20px;
  height: 20px;
}

.contacto-textinput {
  flex: 1;
  color: var(--dl-color-gray-black);
  border-width: 0px;
  padding-left: 12px;
  padding-right: 12px;
}

.contacto-container3 {
  width: 100%;
  height: 56px;
  display: flex;
  max-width: 100%;
  align-items: center;
  line-height: 1.5;
  border-width: 1px;
  margin-right: var(--dl-space-space-halfunit);
  border-radius: 32px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  padding: var(--dl-space-space-unit) var(--dl-space-space-oneandhalfunits);
}

.contacto-icon2 {
  fill: var(--dl-color-grays-gray40);
  width: 20px;
  height: 20px;
}

.contacto-textinput1 {
  flex: 1;
  color: var(--dl-color-gray-black);
  border-width: 0px;
  padding-left: 12px;
  padding-right: 12px;
}

.contacto-container4 {
  width: 100%;
  height: 133px;
  display: flex;
  max-width: auto;
  align-items: center;
  line-height: 1.5;
  border-width: 1px;
  margin-right: var(--dl-space-space-halfunit);
  border-radius: 32px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  padding: var(--dl-space-space-unit) var(--dl-space-space-oneandhalfunits);
}

.contacto-icon4 {
  fill: var(--dl-color-grays-gray40);
  width: 20px;
  height: 20px;
}

.contacto-textarea {
  flex: 1;
  text-align: left;
  border-width: 0px;
  resize: none;
}

.contacto-container5 {
  width: 100%;
  height: 133px;
  display: flex;
  max-width: auto;
  align-items: center;
  line-height: 1.5;
  border-width: 0px;
  margin-right: var(--dl-space-space-halfunit);
  border-radius: 32px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  padding: var(--dl-space-space-unit) var(--dl-space-space-oneandhalfunits);
  justify-content: flex-start;
}

.contacto-button {
  color: var(--dl-color-gray-white);
  width: 100%;
  font-style: normal;
  text-align: center;
  transition: 0.3s;
  font-weight: 400;
  border-width: 0px;
  margin-right: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius8);
  padding: var(--dl-space-space-unit) var(--dl-space-space-twounits);
  background-color: var(--dl-color-logo-03);
}

.contacto-button:hover {
  transform: scale(1.02);
  background-color: var(--dl-color-logo-blanco-01);

}

.contacto-iframe {
  width: 600px;
  height: 500px;
  min-width: 600px;
}

@media (max-width: 1600px) {
  .contacto-hero {
    min-height: 400px;
  }
}

@media (max-width: 1200px) {
  .contacto-iframe {
    width: 663px;
    height: 440px;
  }
}

@media (max-width: 991px) {
  .contacto-hero {
    flex-direction: column;
  }

  .contacto-container1 {
    width: 70%;
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: 0px;
  }

  .contacto-text {
    width: 100%;
  }

  .contacto-iframe {
    height: 500px;
  }
}

@media (max-width: 767px) {
  .contacto-hero {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }

  .contacto-container1 {
    width: 100%;
  }
}

@media (max-width: 479px) {
  .contacto-hero {
    padding: var(--dl-space-space-twounits) var(--dl-space-space-unit);
  }

  .contacto-container1 {
    width: 100%;
    margin-bottom: var(--dl-space-space-unit);
  }

  .contacto-input-container {
    flex-direction: column;
  }

  .contacto-container2 {
    max-width: 100%;
    margin-bottom: var(--dl-space-space-unit);
  }

  .contacto-container3 {
    max-width: 100%;
    margin-bottom: var(--dl-space-space-unit);
  }

  .contacto-container4 {
    max-width: 100%;
    margin-bottom: var(--dl-space-space-unit);
  }

  .contacto-container5 {
    max-width: 100%;
    margin-bottom: var(--dl-space-space-unit);
  }

  .contacto-button {
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }

  .contacto-iframe {
    flex: 1;
    width: 400px;
    height: 518px;
    max-width: 400px;
    min-width: 0px;
    align-self: center;
  }
}
</style>