import axios from 'axios';

const API_URL = 'http://localhost:8020/api/v1/nomina/';
// const API_URL = 'https://34.42.201.233:8080/api/';


class ContactService {
    send(contacto){
        return axios.post(API_URL + 'send', {
            name: contacto.name,
            email: contacto.email,
            message: contacto.message
        })
    }
}

export default new ContactService();