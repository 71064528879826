import axios from "axios";
const API_URL = 'http://localhost:8020/api/i18n';
export async function loadTranslations(locale) {
    try {
        const response = await axios.get(API_URL, {params: {lang: locale}});
        return response.data
    } catch (error) {
        console.error(error);
        throw error;
    }
}